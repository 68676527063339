import React, { useCallback, useEffect, useState } from 'react'
import clubLogo from './images/club_logo.svg'
import bepassLogo from './images/bepass_logo.png'
import batfair from './images/betfair_logo.png'
import './App.sass'

import FaceOnboard from './Biometric/FaceOnboard'
import FaceInstructions from './Biometric/FaceInstructions'
import UserSignup from './User/UserSignup'
import Welcome from './Welcome'

function App() {
  const [userState, setuserState] = useState<string>('')

  const [userToken, setuserToken] = useState<any>('')

  const handleResponse = useCallback((message: any) => {
    setuserState(message.step)
    if (message.token) {
      setuserToken(message.token)
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const generatedToken = window.sessionStorage.getItem('token')
    if (params.get('token')) {
      const currentToken = params.get('token')
      setuserToken(params.get('token'))
      window.sessionStorage.setItem('token', currentToken + '')
      setuserState('faceinformation')
    }

    if (generatedToken) {
      const currentToken = generatedToken
      setuserToken(generatedToken)
      setuserState('faceinformation')
    }
  }, [])
  return (
    <>
      <div className="Container">
        <div className="logos">
          <img src={clubLogo} className="logo" alt="Palmeiras" />
          {/* <img src={batfair} className="logo-bet" alt="Betfair" /> */}
        </div>

        {userState === 'faceinformation' ? (
          <FaceInstructions onResponse={handleResponse} />
        ) : userState === 'facelogin' ? (
          <FaceOnboard token={userToken} />
        ) : userState === 'faceproceed' ? (
          <FaceOnboard token={userToken} />
        ) : userState === 'signup' ? (
          <UserSignup onResponse={handleResponse} />
        ) : (
          <Welcome onResponse={handleResponse} />
        )}
      </div>
      <p className="copyright">
        powered by{' '}
        <a href="http://bepass.com.br" rel="noreferrer" target="_blank">
          <img src={bepassLogo} className="logo-bepass" alt="bepass" />
        </a>
      </p>
    </>
  )
}

export default App
